.section-descricao-detalhes-produto{
    padding: 50px 0px 0 0;

    .nav-tabs{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;

        .active{
            color: #000;
            transition: all 0.5s ease;

            &:before{
                content: "";
                transition: all 0.5s ease;
                position: absolute;
                width: 100%;
                bottom: 0px;
                left: 0px;
                height: 3px;
                border-radius: 6px;
            }
        }
    }

    .nav-item{
        font-family: 'Poppins', sans-serif;
        position: relative;
        border: none;
        color: #333333;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        transition: all 0.5s ease;

        @media(max-width: 540px){
            font-size: 18px;
        }
    }

    .tab-pane{
        font-family: 'Poppins', sans-serif;
        color: #5F5F5F;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        border: 1px solid #C4C4C47D;
        padding: 37px 50px;

        @media(max-width: 540px){
            padding: 15px;
        }
    }
}
