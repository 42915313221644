/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 900px;
    margin-top: 50px !important;
    margin: 0 auto;

    /* The actual content */
    .content {
        padding: 20px 30px;
        background-color: white;
        position: relative;
        border-radius: 6px;
        text-align: justify;
    }

    /* Place the container to the left */
    .left {
        left: 0;

        &:before {
            content: " ";
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            right: 30px;
            border: medium solid white;
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent white;
        }
    }

    /* Place the container to the right */
    .right {
        left: 50%;

        /* Fix the circle for containers on the right side */
        &:after {
            left: -11px;
        }

        /* Add arrows to the left container (pointing right) */
        &:before {
            content: " ";
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            left: 30px;
            border: medium solid white;
            border-width: 10px 10px 10px 0;
            border-color: transparent white transparent transparent;
        }
    }



    /* Container around content */
    .container-timeline {
        padding: 10px 40px;
        position: relative;
        background-color: inherit;
        width: 50%;

        /* The circles on the timeline */
        &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            right: -12px;
            background-color: white;
            border: 4px solid #FF9F55;
            top: 15px;
            border-radius: 50%;
            z-index: 1;
        }


    }

    /* The actual timeline (the vertical ruler) */
    &:after {
        content: '';
        position: absolute;
        width: 6px;
        background-color: white;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
    }


}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 991px) {

    /* Place the timelime to the left */
    .timeline::after {
        left: 9% !important;
        display: flex;
        justify-content: center;
    }

    .timeline  {
        .right::after {
            left: -20px !important;
        }
    }

    /* Full-width containers */
    .container-timeline {
        width: 90% !important;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .container-timeline::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .left::after,
    .right::after {
        display: flex;
        justify-content: center;
    }

    /* Make all right containers behave like the left ones */
    .right {
        left: 10% !important;
    }
}
